import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import '../../../node_modules/video-react/dist/video-react.css'
import { Player } from 'video-react'
import './style.css'

const Videos = () => {
  const { t } = useTranslation()
  return (
    <>
      <Container fluid className="p-5">
        <div className="heading-style col-lg-12 col-md-12 col-sm-12  mb-5 mt-5 text-center">
          <h6 className="text-dark">{t('we')}</h6>
          <h3>{t('smi')}</h3>
        </div>
        <Row className="mt-5">
          <Col lg={4} md={6} sm={12} xs={12} className="mb-sm-6">
            <Player playsInline width="100%" height="315px" src="video-2022.mp4" />
            {/* <video width="100%" height="315" controls>
              <source src="video-2022.mp4" type="video/mp4" />
            </video> */}
          </Col>
          <Col lg={4} md={6} sm={12} xs={12}>
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/fhVsXC-j5Qs"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Col>
          <Col lg={4} md={6} sm={12} xs={12} className="mb-5">
            <iframe
              title="1"
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/sTIsrKdBT6Q"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Col>
          <Col lg={4} md={6} sm={12} xs={12}>
            <iframe
              title="2"
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/GVE4qa5d4F4"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Col>
          <Col lg={4} md={6} sm={12} xs={12}>
            <iframe
              title="3"
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/R08pReNnNyk"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Col>
          <Col lg={4} md={6} sm={12} xs={12}>
            <iframe
              title="4"
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/vDrGe8lUcGk"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Col>
          <Col lg={4} md={6} sm={12} xs={12}>
            <iframe
              title="5"
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/ZzbW48S5XgE"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Col>
          <Col lg={4} md={6} sm={12} xs={12}>
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/SHQPUHgnq0o?si=p0UKH9zol737e9ib"
              title="6"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default Videos
